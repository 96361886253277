<template>
    <CCard>
        <CCardHeader> 
            <div class="filter-container">
                <div class="mr-2">
                    <div class="input-group">
                        <div class="input-group-prepend ">
                        <span class="input-group-text py-0">
                            <small>From</small></span>
                        </div>
                            <Datepicker v-model="delivery_date_from" input-class="form-control form-control-sm" placeholder="" />
                    </div>
                </div>
                <div  class="mr-2">
                    <div class="input-group">
                        <div class="input-group-prepend ">
                        <span class="input-group-text py-0">
                            <small>To</small></span>
                        </div>
                        <Datepicker v-model="delivery_date_to" input-class="form-control form-control-sm" placeholder="" />
                    </div>
                </div>
                <div>
                    <button class="px-3 btn btn-info btn-sm" @click="reloadTable">
                        <i class="fa fa-filter"></i> Filter
                    </button>
                </div>
                &nbsp;
                <div>
                    <button class="px-3 btn btn-success btn-sm" @click="zoombie">
                        <i class="fa fa-search-plus"></i>
                    </button>
                </div>
            </div>
            
       </CCardHeader>
        <CCardBody>
            <div class="loader" v-show="isLoading">
                <i class="fa fa-spinner fa-spin"></i> Loading chart
            </div>
            <CChartHorizontalBar ref="chart" v-if="!isLoading"
                        :datasets="defaultDatasets"
                        :labels="labels"
                        :options="barchartOption"
                    /> 
        </CCardBody>
      </CCard>
</template>
<script>
import config from '../../config';
import axios from '../../axios';
import moment from 'moment';
import { CChartHorizontalBar } from '@coreui/vue-chartjs'
import Datepicker from 'vuejs-datepicker';
import 'regenerator-runtime/runtime';

export default {
    name : 'BookingCommodityChart',
    components: { CChartHorizontalBar , Datepicker},
    computed: {
        defaultDatasets () {
        return [
            {
            label: 'Trips',
            data: this.datasets,
            backgroundColor: this.colors,
            
            }
        ]
        }
    },
    data() {
        return {
            labels: [],
            datasets: [],
            isLoading: false,
            delivery_date_to: new Date(),
	        delivery_date_from: moment(new Date()).subtract(1, 'month')._d,
            barchartOption: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                hover: {
                    mode: 'index',
                    intersect: false
                },
                legend: { position: 'top'},
                title: { display: true, text: 'Bookings by Commodity Type'},
                legend: {
                    display: false
                },
                scales: {
                    xAxes:[{
                        ticks: {
                            beginAtZero: true
                        },
                        display: true
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            autoSkip: false,
                            fontSize: 9,
                            
                        },
                        gridLines: {
                            display: false
                        },
                    }]
                }
            },
            zoomed: false
        }
    },
    mounted() {
         this.loadBookingCommodityChart() 
    },
    methods: {
        zoombie() {
            this.zoomed = !this.zoomed;
            this.$emit('zoomme',true)
            
            
        },
        reloadTable() {
            this.datasets = [];
            this.loadBookingCommodityChart()
        },
        async loadBookingCommodityChart() {
            this.isLoading = true;
            var delivery_date_from = this.delivery_date_from;
            if(delivery_date_from != ""){
                delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
            }

            var delivery_date_to = this.delivery_date_to;
            if(delivery_date_to != ""){
                delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
                delivery_date_to = delivery_date_to + 86399; 
            }
            await axios.get(config.api_path+'/stats/booking-commodity',{
                params: {
                    from : delivery_date_from,
                    to: delivery_date_to,
                    company_id : config.global_user_data.company_id
                }
            }).then(res => {
                const { data } = res.data;
                this.labels = [];
                this.colors = [];
                this.datasets = [];
                data.forEach(d => {
                    this.labels.push(d.setting_name)
                    this.datasets.push(d.count);
                    this.colors.push(this.randomColor())
                })
                
            })
            console.log(this.datasets)
            this.isLoading = false;
        },
        randomColor() {
            var r = Math.floor(Math.random() * 255);
            var g = Math.floor(Math.random() * 255);
            var b = Math.floor(Math.random() * 255);
            return "rgb(" + r + "," + g + "," + b + ")";
        }
    }
   
}
</script>