<style>
    .input-group > .vdp-datepicker input[type="text"]{
        border-radius: 0px 3px 3px 0px !important;
    }
    .filter-container {
        display:flex;
        flex-wrap: nowrap;
    }
</style>
<template>
<div>
    <!-- <BookingCommodityChart @zoomme="zoomme" v-show="zoomg" />
    <CCardGroup columns class="card-columns cols-2">
        <BookingStatusChart />
        <BookingCommodityChart @zoomme="zoomme" v-show="!zoomg" />
    </CCardGroup> -->
        <div id="my-superset-containers" class="embedded-superset">
            
        </div>
   
</div>
</template>
<script>
import BookingStatusChart from './charts/BookingStatusChart';
import BookingCommodityChart from './charts/BookingCommodityChart';
import config from '../config.js';
import axios from 'axios'
// import { embedDashboard } from "@superset-ui/embedded-sdk";
export default {
    name : 'Home',
    data() {
        return {
            zoomg : false
        }
    },
    components: { BookingStatusChart, BookingCommodityChart},
    methods : {
        zoomme(ok) {
            console.log('asd', ok)
            this.zoomg = !this.zoomg
        }
    },
    created() {
        this.$nextTick(() => {
            async function getToken(id) {
                const url = config.api_path + '/superset/guest-token/' + id;
                const res = await axios.get(url);
                const data = res.data;
                return data?.token?.token;
            }

            const id = config.global_user_data.company.superset_embed_key;
            const dashboard_id = config.global_user_data.company.superset_dashboard_id;

            supersetEmbeddedSdk.embedDashboard({
                id,
                supersetDomain: "https://supa.sloop.asia",
                mountPoint: document.getElementById("my-superset-containers"),
                fetchGuestToken: () => getToken(dashboard_id),
                dashboardUiConfig: {
                    hideTitle: true,
                    filters: {
                        expanded: true
                    }
                }
            });
        })
    }
}
</script>

<style>
.embedded-superset iframe {
  width: 100% !important;
  height: 1500px !important;
}
</style>