<template>
    <CCard>
        <CCardHeader> 
            <div class="filter-container">
                <div class="mr-2">
                    <div class="input-group">
                        <div class="input-group-prepend ">
                        <span class="input-group-text py-0">
                            <small>From</small></span>
                        </div>
                            <Datepicker v-model="delivery_date_from" input-class="form-control form-control-sm" placeholder="" />
                    </div>
                </div>
                <div  class="mr-2">
                    <div class="input-group">
                        <div class="input-group-prepend ">
                        <span class="input-group-text py-0">
                            <small>To</small></span>
                        </div>
                        <Datepicker v-model="delivery_date_to" input-class="form-control form-control-sm" placeholder="" />
                    </div>
                </div>
                <div>
                    <button class="px-3 btn btn-info btn-sm" @click="reloadTable">
                        <i class="fa fa-filter"></i> Filter
                    </button>
                </div>
            </div>
       </CCardHeader>
        <CCardBody>
            <div class="loader" v-show="isLoading">
                <i class="fa fa-spinner fa-spin"></i> Loading chart
            </div>
            <CChartPie v-if="!isLoading"
                        :datasets="defaultDatasets"
                        :labels="labels"
                        :options="{title: { display: true, text: 'Bookings by Status'},}"
                    /> 
        </CCardBody>
      </CCard>
</template>
<script>
import config from '../../config';
import axios from '../../axios';
import moment from 'moment';
import { CChartPie } from '@coreui/vue-chartjs'
import Datepicker from 'vuejs-datepicker';
import 'regenerator-runtime/runtime';
export default {
    name : 'BookingStatusChart',
    components: { CChartPie , Datepicker},
    computed: {
    
        blankData () {
        return [
            {
                label: [],
                data: [0,0,0,0,0,0],
            }
        ]
        },
        defaultDatasets () {
        return [
            {
            label: 'Trips',
            data: this.datasets,
            backgroundColor: [
                'rgba(255, 99, 132)',
                'rgba(54, 162, 235)',
                'rgba(255, 206, 86)',
                'rgba(75, 192, 192)',
                'rgba(153, 102, 255)',
                'rgba(255, 159, 64)'
            ],
            
            }
        ]
        }
    },
    data() {
        return {
            labels: [
                'Under Review',
                'Approved',
                'Dispatched',
                'Delivered',
                'Completed',
                'Cancelled'
            ],
            datasets: [],
            isLoading: false,
            delivery_date_to: new Date(),
	        delivery_date_from: moment(new Date()).subtract(1, 'month')._d,
        }
    },
    mounted() {
        this.loadBookingStatusChart();    
    },
    methods: {
        reloadTable(type) {
            this.loadBookingStatusChart()
        },
        async loadBookingStatusChart() {
            this.isLoading = true;
            var delivery_date_from = this.delivery_date_from;
            if(delivery_date_from != ""){
                delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
            }

            var delivery_date_to = this.delivery_date_to;
            if(delivery_date_to != ""){
                delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
                delivery_date_to = delivery_date_to + 86399; 
            }
            await axios.get(config.api_path+'/stats/booking-status',{
                params: {
                    from : delivery_date_from,
                    to: delivery_date_to,
                    company_id : config.global_user_data.company_id
                }
            }).then(res => {
                const { data } = res.data;
                this.datasets = [];
                this.labels.forEach(label => {
                    console.log(label)
                    let found = false;
                    for (const d of data) {
                        if(d.status === label?.toLowerCase()) {
                            this.datasets.push(d.count);
                            found = true;
                            break;
                        }
                    }
                    if(!found) {
                        this.datasets.push(0)
                    }
                })
                
            })
            console.log(this.datasets)
            this.isLoading = false;
        }
    }
}
</script>